export const male_normal = [
    {
        part: 'mouth',
        color: [
            ['#000']
        ],
        coords: [ 
        [ 11, 18],
        [ 14, 18],
        [ 14, 19],
        [ 11, 19]
        ]
    },
    { // nose
        color: [
            ['#000']
        ],
        coords: [ 
        [ 12, 15],
        [ 14, 15],
        [ 14, 16],
        [ 12, 16]
        ]
    },

    { // eyeBall right
        color: [
            ['#000']
        ],
        coords: [ 
        [  9, 12],
        [ 10, 12],
        [ 10, 13],
        [  9, 13]
        ]
    },
    { // eye right
        coords: [ 
        [ 10, 12],
        [ 11, 12],
        [ 11, 13],
        [ 10, 13]
        ]
    },
    { // eyeBrow right
        coords: [ 
        [ 9, 11],
        [ 11, 11],
        [ 11, 12],
        [ 9, 12]
        ]
    },

    { // eyeBall left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 14, 12],
        [ 15, 12],
        [ 15, 13],
        [ 14, 13]
        ]
    },
    { // eye left
        coords: [ 
        [ 15, 12],
        [ 16, 12],
        [ 16, 13],
        [ 15, 13]
        ]
    },
    { // eyeBrow left
        coords: [ 
        [ 14, 11],
        [ 16, 11],
        [ 16, 12],
        [ 14, 12]
        ]
    },
    {
        // shade
        coords: [ 
            [ 8,  8],
            [10,  8],
            [10,  7],
            [ 9,  7],
            [ 9,  9],
            [ 8,  9]
        ]
    }
  ]