export const cigarette = [
    { // White
        color: ['#c6c6c6'],
        coords: [[14, 18], [15, 18], [15, 19], [14, 19]]
    },
    { // Fire
        color: ['#e25b26'],
        coords: [[16, 18], [15, 18], [15, 19], [16, 19]]
    },
    { 
        color: ['#000'],
        coords: [[14, 17], [14, 18], [16, 18], [16, 20], [14, 20], [14, 19], [17, 19], [17, 18], [16, 18], [16, 17]]
    },
    { // starting block
        color: ['#000'],
        coords: [[14, 19], [14, 18], [13, 18], [13, 19]]
    }
];
