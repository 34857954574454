export const noggles_male = [
    {   part: 'noggles',
        color: [
            ['#FF638D']
        ],
        coords: [
            [ 8, 12],
            [ 6, 12],
            [ 6, 11],
            [ 8, 11],
            [ 8, 10],
            [12, 10],
            [12, 11],
            [13, 11],
            [13, 10],
            [17, 10],
            [17, 14],
            [13, 14],
            [13, 12],
            [12, 12],
            [12, 14],
            [ 8, 14]
        ]
    },

    { // eyeBall right
        color: [
            ['#fff']
        ],
        coords: [ 
        [  9, 11],
        [ 10, 11],
        [ 10, 13],
        [  9, 13]
        ]
    },
    { // eye right
        color: [
            ['#000']
        ],
        coords: [ 
        [ 10, 11],
        [ 11, 11],
        [ 11, 13],
        [ 10, 13]
        ]
    },


    { // eyeBall left
        color: [
            ['#fff']
        ],
        coords: [ 
        [ 14, 11],
        [ 15, 11],
        [ 15, 13],
        [ 14, 13]
        ]
    },
    { // eye left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 15, 11],
        [ 16, 11],
        [ 16, 13],
        [ 15, 13]
        ]
    },
  ]