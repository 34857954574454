

export const clown_nose_female = [
    {
    color: [
        ['#d60000']
    ],
    coords: [ 
    [ 12, 15],
    [ 14, 15],
    [ 14, 17],
    [ 12, 17]
    ]
}
]