import { convertColorsToRgba, selectRandom, debugPoints } from '../utils/Utils';
import { poissonDiscWithinShape } from '../utils/Disc';
import { extractUniqueColors } from '../utils/Colors';
import { conditions, applyAccessoryConditions, filterExclusiveAccessories, applyFaceConditions } from '../utils/conditions';
import { setupLevyFlightWithSkinCoords } from '../utils/Levy';

const Punk = (p, cellSize, traits, setTraits, debug = false) => {
  const uniqueColors = extractUniqueColors(p);

  // Function to reorder accessories so that "Cigarette" is last
  const reorderAccessories = (accessories) => {
    const cigaretteIndex = accessories.findIndex(acc => acc.name === 'Cigarette');
    if (cigaretteIndex > -1) {
      const [cigarette] = accessories.splice(cigaretteIndex, 1);
      accessories.push(cigarette);
    }
    return accessories;
  };

  // Main drawing logic
  const draw = (selectedSex, selectedFace, selectedHair, modifiedAccessories, skinIndex) => {
    const skinColors = selectedSex.data[1].color[skinIndex];

    drawSexTraits(selectedSex, skinIndex);
    drawFaceTraits(selectedFace, selectedSex, skinIndex);

    if (selectedHair && selectedHair.data) {
      drawTraitGroup(p, selectedHair.data, false);
    }

    const reorderedAccessories = reorderAccessories(applyAccessoryConditions(modifiedAccessories, selectedHair, skinIndex));
    
    if (reorderedAccessories.length > 0) {
      reorderedAccessories.forEach(accessory => {
        if (accessory.data) {
          drawTraitGroup(p, accessory.data, false);
        }
      });
    }
  };

  const getTraitColor = (trait, index, skinColors) => {
    if (trait.part === 'mouth') {
      return trait.color[0];
    } else if ([0, 1, 2, 5].includes(index)) {
      return trait.color[0];
    } else if ([3, 6].includes(index)) {
      return skinColors[1];
    } else if ([4, 7].includes(index)) {
      return skinColors[2];
    } else if (index === 8) {
      return skinColors[3];
    }
    return trait.color[0];
  };

  const drawLayer = (coords, color, showDebug) => {
    p.fill(color);
    p.noStroke();
    p.beginShape();
    coords.forEach(([x, y]) => p.vertex(x * cellSize, y * cellSize));
    p.endShape(p.CLOSE);

    if (showDebug) {
      debugPoints(coords, cellSize, p);
    }
  };

  const drawTraitGroup = (p, traits, showDebug) => {
    traits.forEach(trait => {
      const color = Array.isArray(trait.color) ? selectRandom(trait.color) : trait.color[0];
      drawLayer(trait.coords, color, showDebug);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
      if (trait.levy) {
        setupLevyFlightWithSkinCoords(p, trait, cellSize, p.color(...p.color(color).levels.slice(0, 3), 102)); // 40% alpha
      }
    });
  };

  const drawFaceTraits = (faceData, selectedSex, skinIndex) => {
    if (!faceData || !Array.isArray(faceData.data)) {
      console.error('No face data available:', faceData);
      return;
    }

    const skinColors = selectedSex.data[1].color[skinIndex];
    faceData.data.forEach((trait, index) => {
      let color = getTraitColor(trait, index, skinColors);
      if (!color) {
        console.error('No color found for trait:', trait);
        return;
      }
      drawLayer(trait.coords, color, false);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
    });
  };

  const drawSexTraits = (selectedSex, skinIndex) => {
    selectedSex.data.forEach((trait, index) => {
      const color = index === 0 ? trait.color[0] : trait.color[skinIndex][0];
      drawLayer(trait.coords, color, false);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
    });
  };

  return { draw, selectRandom, applyFaceConditions };
};

export default Punk;
