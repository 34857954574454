import { pink_male } from './pink_male.js';
import { pink_female } from './pink_female.js';
import { short_female } from './short_female.js';
import { straight_hair_female } from './straight_hair_female.js';
import { bandana_female } from './bandana_female.js';
import { wild_hair_white_female } from './wild_hair_white_female.js';
import { stringy_hair_male } from './stringy_hair_male.js';

export const hair = [
     { name: 'Short Hair', sex: 'female', data: short_female, length: 'short' },
     { name: 'Straight Hair', sex: 'female', data: straight_hair_female, length: 'long' },
     { name: 'Pink Hair', sex: 'male', data: pink_male, length: 'short' },
     { name: 'Pink Hair', sex: 'female', data: pink_female, length: 'long' },
     { name: 'Wild Hair White', sex: 'female', data: wild_hair_white_female, length: 'long' },
     { name: 'Stringy Hair', sex: 'male', data: stringy_hair_male },
     { name: 'Bandana', sex: 'female', data: bandana_female},
     { name: 'none', data: null },
];