export function extractUniqueColors(p) {
    const img = p.loadImage('/img/punk.png');
    const palette = new Set();
    img.loadPixels();
    for (let i = 0; i < img.width; i++) {
      for (let j = 0; j < img.height; j++) {
        const col = img.get(i, j);
        palette.add(col);
      }
    }
    return Array.from(palette);
  }