export const noggles_female = [

    {   part: 'noggles',
        coords: [
            [ 8, 13],
            [ 7, 13],
            [ 7, 12],
            [ 8, 12],
            [ 8, 11],
            [12, 11],
            [12, 12],
            [13, 12],
            [13, 11],
            [17, 11],
            [17, 15],
            [13, 15],
            [13, 13],
            [12, 13],
            [12, 15],
            [ 8, 15]
        ]
    },

    { // eyeBall right
        color: [
            ['#fff']
        ],
        coords: [ 
        [  9, 12],
        [ 10, 12],
        [ 10, 14],
        [  9, 14]
        ]
    },
    { // eye right
        color: [
            ['#000']
        ],
        coords: [ 
        [ 10, 12],
        [ 11, 12],
        [ 11, 14],
        [ 10, 14]
        ]
    },


    { // eyeBall left
        color: [
            ['#fff']
        ],
        coords: [ 
        [ 14, 12],
        [ 15, 12],
        [ 15, 14],
        [ 14, 14]
        ]
    },
    { // eye left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 15, 12],
        [ 16, 12],
        [ 16, 14],
        [ 15, 14]
        ]
    },
  ]