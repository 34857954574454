import React from 'react';
import { P5Provider } from './contexts/P5Context';
import Viewport from './components/Viewport';

import Keypress from './controls/Keypress';
import Tweak from './controls/Tweak';

import './App.css';

function App() {
  return (
    <P5Provider>
      <div className="App">
        <Viewport />
          <Keypress />
          <Tweak/>
      </div>
    </P5Provider>
  );
}

export default App;
