export const clown_nose_male = [
    {
    color: [
        ['#d60000']
    ],
    coords: [ 
    [ 12, 14],
    [ 14, 14],
    [ 14, 16],
    [ 12, 16]
    ]
}
]