import { convertColorsToRgba, debugPoints } from '../utils/Utils';
import { poissonDiscWithinShape } from '../utils/Disc';
import { extractUniqueColors } from '../utils/Colors';
import { conditions, applyAccessoryConditions, filterExclusiveAccessories } from '../utils/conditions'; // Import conditions and filtering

import { sex } from '../assets/traits/sex/_index.js';
import { face } from '../assets/traits/face/_index.js';
import { hair } from '../assets/traits/hair/_index.js';
import { accessories } from '../assets/traits/accessories/_index.js';

// Manually set traits for Ladyboy
const ladyboyTraits = {
  type: 'human',
  sex: 'male',
  hair: 'none',
  accessories: ['Hot Lipstick']
};

const Ladyboy = (p, cellSize, traits, setTraits, debug = false) => {
  const r = 21;
  const k = 30;
  const uniqueColors = extractUniqueColors(p);

  const selectedSex = sex.find(s => s.name === 'male' && s.type === 'human');
  if (!selectedSex) {
    console.error('No sex data available for male human.');
    return;
  }
  const skinIndex = Math.floor(Math.random() * selectedSex.data[1].color.length);
  const faceOptions = face.filter(f => f.sex === 'male' && f.type === 'human');

  if (faceOptions.length === 0) {
    console.error('No face options found for the selected sex:', selectedSex.name);
  }

  const selectedFace = faceOptions.length > 0 ? selectRandom(faceOptions) : { name: 'defaultFace', data: [] };

  const selectedHair = hair.find(h => h.name === ladyboyTraits.hair) || { name: 'none', data: null };
  const selectedAccessories = ladyboyTraits.accessories.map(accName => accessories.find(a => a.name === accName) || { name: accName, data: null });

  const filteredAccessories = filterExclusiveAccessories(selectedAccessories);

  const modifiedFace = applyFaceConditions(selectedFace, filteredAccessories);
  const modifiedAccessories = applyAccessoryConditions(filteredAccessories, selectedHair);

  setTraits([
    {
      background: false,
      colors: [],
      type: ladyboyTraits.type,
      sex: ladyboyTraits.sex,
      hair: selectedHair.name,
      accessories: modifiedAccessories.map(acc => acc.name)
    }
  ]);

  // Drawing functions
  const drawLayer = (coords, color, showDebug) => {
    p.fill(color);
    p.noStroke();
    p.beginShape();
    coords.forEach(([x, y]) => p.vertex(x * cellSize, y * cellSize));
    p.endShape(p.CLOSE);

    if (showDebug) {
      debugPoints(coords, cellSize, p);
    }
  };

  const drawTraitGroup = (p, traits, showDebug) => {
    traits.forEach(trait => {
      const color = Array.isArray(trait.color) ? selectRandom(trait.color) : trait.color[0];
      drawLayer(trait.coords, color, showDebug);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
    });
  };

  const drawFaceTraits = (faceData, selectedSex, skinIndex) => {
    if (!faceData || !Array.isArray(faceData.data)) {
      console.error('No face data available:', faceData);
      return;
    }

    const skinColors = selectedSex.data[1].color[skinIndex];
    faceData.data.forEach((trait, index) => {
      let color = getTraitColor(trait, index, skinColors);
      if (!color) {
        console.error('No color found for trait:', trait);
        return;
      }
      drawLayer(trait.coords, color, false);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
    });
  };

  const drawSexTraits = (selectedSex, skinIndex) => {
    selectedSex.data.forEach((trait, index) => {
      const color = index === 0 ? trait.color[0] : trait.color[skinIndex][0];
      drawLayer(trait.coords, color, false);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, trait.coords, cellSize, colorsToUse);
      }
    });
  };

  // Main drawing logic
  drawSexTraits(selectedSex, skinIndex);
  drawFaceTraits(modifiedFace, selectedSex, skinIndex);

  if (selectedHair && selectedHair.data) {
    drawTraitGroup(p, selectedHair.data, false);
  }

  if (modifiedAccessories.length > 0) {
    modifiedAccessories.forEach(accessory => {
      if (accessory.data) {
        drawTraitGroup(p, accessory.data, false);
      }
    });
  }

  // Helper functions

  function selectRandom(array) {
    return array.length > 0 ? array[Math.floor(Math.random() * array.length)] : { name: 'default', data: [] };
  }

  function getTraitColor(trait, index, skinColors) {
    if (trait.part === 'mouth') {
      return trait.color[0];
    } else if ([0, 1, 2, 5].includes(index)) {
      return trait.color[0];
    } else if ([3, 6].includes(index)) {
      return skinColors[1];
    } else if ([4, 7].includes(index)) {
      return skinColors[2];
    } else if (index === 8) {
      return skinColors[3];
    }
    return trait.color[0];
  }

  function applyFaceConditions(selectedFace, selectedAccessories) {
    let modifiedFace = { ...selectedFace, data: [...selectedFace.data] };

    selectedAccessories.forEach(accessory => {
      if (conditions[accessory.name]) {
        modifiedFace.data = conditions[accessory.name](modifiedFace.data);
      }
    });

    return modifiedFace;
  }
};

export default Ladyboy;
