// sketches/drawSelfie.js
import { convertColorsToRgba, poissonDiscWithinShape } from '../utils/Utils';
import { extractUniqueColors } from '../utils/Colors';

import { sex } from '../assets/traits/sex/_index.js';
import { face } from '../assets/traits/face/_index.js';
import { hair } from '../assets/traits/hair/_index.js';
import { accessories } from '../assets/traits/accessories/_index.js';

const SelfieStick = (p, cellSize, traits, setTraits) => {
  const offset = { x: -12, y: 1 }; // Specific offset for Selfie
  const r = 21; // Minimum distance between points
  const k = 30; // Maximum number of attempts before marking a sample as inactive
  const uniqueColors = extractUniqueColors(p);

  setTraits([
    {
      background: true,
      colors: ['#000000', '#FF0000'],
      type: 'human',
      sex: 'female',
      hair: 'Pink Hair',
      accessories: ['selfieStick', 'Hot Lipstick']
    }
  ]);

  const drawLayer = (coords, color) => {
    p.fill(color);
    p.noStroke();
    p.beginShape();
    coords.forEach(([x, y]) => p.vertex(x * cellSize, y * cellSize));
    p.endShape(p.CLOSE);
  };

  const drawTraitGroup = (traits) => {
    traits.forEach(trait => {
      const adjustedCoords = applyOffset(trait.coords, offset);
      drawLayer(adjustedCoords, trait.color[0]);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, adjustedCoords, r, k, cellSize, colorsToUse);
      }
    });
  };

  const drawFaceTraits = (selectedFace, selectedSex) => {
    const skinColors = selectedSex.data[1].color[0]; // Using the first skin color set
    selectedFace.data.forEach((trait, index) => {
      let color;
      if ([0, 1, 2, 5].includes(index)) {
        color = trait.color[0];
      } else if ([3, 6].includes(index)) {
        color = skinColors[1];
      } else if ([4, 7].includes(index)) {
        color = skinColors[2];
      } else if (index === 8) {
        color = skinColors[3];
      }
      const adjustedCoords = applyOffset(trait.coords, offset);
      drawLayer(adjustedCoords, color);
      if (trait.postProcessing) {
        const colorsToUse = trait.colors ? convertColorsToRgba(trait.colors) : uniqueColors;
        poissonDiscWithinShape(p, adjustedCoords, r, k, cellSize, colorsToUse);
      }
    });
  };

  const drawSexTraits = (selectedSex) => {
    selectedSex.data.forEach((trait, index) => {
      const color = trait.color[0];
      if (color) {
        const adjustedCoords = applyOffset(trait.coords, offset);
        drawLayer(adjustedCoords, color[0]);
      } else {
        console.error("Undefined color for skin index 0 in trait", trait);
      }
    });
  };

  const selectedSex = sex.find(s => s.name === 'female');
  if (!selectedSex) {
    console.error("No female traits found in the data");
    return;
  }

  drawSexTraits(selectedSex);

  const selectedFace = face.find(f => f.sex === 'female');
  if (!selectedFace) {
    console.error("No face traits found for female in the data");
    return;
  }
  drawFaceTraits(selectedFace, selectedSex);

  const selectedHair = hair.find(h => h.name === 'Pink Hair' && h.sex === 'female');
  if (!selectedHair) {
    console.error("No pink hair traits found in the data");
    return;
  }
  drawTraitGroup(selectedHair.data);

  const selfieStick = accessories.find(a => a.name === 'selfieStick');
  if (!selfieStick) {
    console.error("No selfieStick accessories found in the data");
    return;
  }
  drawTraitGroup(selfieStick.data);

  // Helper functions

  function applyOffset(coords, offset) {
    return coords.map(([x, y]) => [x + offset.x, y + offset.y]);
  }

};

export default SelfieStick;
