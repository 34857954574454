import { convertColorsToRgba } from "./Utils";

const r = 21;
const k = 30;

export function poissonDiscWithinShape(p, shapeCoords, cellSize, colorArray) {

    let active = [];
    let points = [];
    colorArray = convertColorsToRgba(colorArray);
    
    const boundingBox = getBoundingBox(shapeCoords);
    const initialPoint = getValidInitialPoint(p, cellSize, boundingBox, shapeCoords);
  
    if (!initialPoint) {
      console.warn("No valid initial point found within the shape");
      return;
    }
  
    active.push(initialPoint);
    points.push({ point: initialPoint, color: p.random(colorArray) });
  
    while (active.length > 0) {
      const randIndex = Math.floor(p.random(active.length));
      const pos = active[randIndex];
      let found = false;
  
      for (let n = 0; n < k; n++) {
        const sample = getRandomSample(p, pos, r);
        const sampleCoords = [sample.x / cellSize, sample.y / cellSize];
  
        if (isSampleValid(cellSize, sample, boundingBox, shapeCoords, points, r)) {
          found = true;
          active.push(sample);
          points.push({ point: sample, color: p.random(colorArray) });
          break;
        }
      }
  
      if (!found) active.splice(randIndex, 1);
    }
  
    const rectCount = drawPoints(p, points, cellSize);
  }
  
  function getBoundingBox(coords) {
    return {
      minX: Math.min(...coords.map(([x]) => x)),
      maxX: Math.max(...coords.map(([x]) => x)),
      minY: Math.min(...coords.map(([, y]) => y)),
      maxY: Math.max(...coords.map(([, y]) => y)),
    };
  }
  
  function getValidInitialPoint(p, cellSize, boundingBox, shapeCoords) {
    const maxAttempts = 100; // Max attempts to find a valid initial point
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const initialPoint = p.createVector(p.random(boundingBox.minX, boundingBox.maxX) * cellSize, p.random(boundingBox.minY, boundingBox.maxY) * cellSize);
      if (isPointInPolygon([initialPoint.x / cellSize, initialPoint.y / cellSize], shapeCoords)) {
        return initialPoint;
      }
    }
    return null; // Return null if no valid initial point is found
  }
  
  function getRandomSample(p, pos, r) {
    const sample = p.createVector(p.random(-1, 1), p.random(-1, 1)).mult(p.random(r, 2 * r)).add(pos);
    return sample;
  }
  
  function isSampleValid(cellSize, sample, boundingBox, shapeCoords, points, r) {
    const sampleCoords = [sample.x / cellSize, sample.y / cellSize];
    return (
      sampleCoords[0] >= boundingBox.minX && sampleCoords[0] <= boundingBox.maxX &&
      sampleCoords[1] >= boundingBox.minY && sampleCoords[1] <= boundingBox.maxY &&
      isPointInPolygon(sampleCoords, shapeCoords) &&
      !points.some(({ point }) => sample.dist(point) < r)
    );
  }
  
  function drawPoints(p, points, cellSize) {
    points.forEach(({ point, color }) => {
      p.fill(color);
      p.noStroke();
      p.rect(point.x - cellSize / 48, point.y - cellSize / 48, cellSize / 24, cellSize / 24);
    });
    return points.length; // Return the number of rectangles drawn
  }
  
  function isPointInPolygon(point, polygon) {
    let isInside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const [xi, yi] = polygon[i];
      const [xj, yj] = polygon[j];
      const intersect = ((yi > point[1]) !== (yj > point[1])) && (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
      if (intersect) isInside = !isInside;
    }
    return isInside;
  }
