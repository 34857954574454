export const earring_female = [
        {
        color: [
            ['#ffd926']
        ],
        coords: [ 
        [ 6, 14],
        [ 7, 14],
        [ 7, 15],
        [ 6, 15]
        ]
    },
    {
        color: [
            ['#000000']
        ],
        coords: [ 
            [ 6, 14],
            [ 5, 14],
            [ 5, 15],
            [ 6, 15]
        ]
    },
    {
        color: [
            ['#000']
        ],
        coords: [ 
            [ 6, 16],
            [ 7, 16],
            [ 7, 15],
            [ 6, 15]
        ]
    },
  ]