import Punk from './Punk';
import { extractUniqueColors } from '../utils/Colors';
import { conditions, applyAccessoryConditions, filterExclusiveAccessories } from '../utils/conditions'; // Import conditions and filtering

import { sex } from '../assets/traits/sex/_index.js';
import { face } from '../assets/traits/face/_index.js';
import { hair } from '../assets/traits/hair/_index.js';
import { accessories } from '../assets/traits/accessories/_index.js';

// Define probabilities for the number of attributes
const attributeProbabilities = [
  { count: 0, probability: 8 / 10000 },
  { count: 1, probability: 333 / 10000 },
  { count: 2, probability: 3560 / 10000 },
  { count: 3, probability: 4501 / 10000 },
  { count: 4, probability: 1420 / 10000 },
  { count: 5, probability: 166 / 10000 },
  { count: 6, probability: 11 / 10000 },
  { count: 7, probability: 1 / 100000 }
];

// Function to select the number of attributes based on defined probabilities
const selectAttributeCount = () => {
  const rand = Math.random();
  let cumulativeProbability = 0;

  for (const attr of attributeProbabilities) {
    cumulativeProbability += attr.probability;
    if (rand < cumulativeProbability) {
      return attr.count;
    }
  }
  return 0; // Fallback in case of error
};

const Base = (p, cellSize, traits, setTraits, debug = false) => {
  const { draw, selectRandom, applyFaceConditions } = Punk(p, cellSize, traits, setTraits, debug);
  const uniqueColors = extractUniqueColors(p);

  const selectedSex = selectRandom(sex.filter(a => a.type !== 'alien'));
  const skinIndex = Math.floor(Math.random() * selectedSex.data[1].color.length);
  const faceOptions = face.filter(f => f.sex === selectedSex.name && f.type === 'human');

  if (faceOptions.length === 0) {
    console.error('No face options found for the selected sex:', selectedSex.name);
  }

  const selectedFace = faceOptions.length > 0 ? selectRandom(faceOptions) : { name: 'defaultFace', data: [] };

  // Select attributes based on the calculated probabilities
  let attributeCount = selectAttributeCount();
  let selectedHair = null;
  let selectedAccessories = [];

  if (attributeCount > 0) {
    selectedHair = selectRandom(hair.filter(h => !h.sex || h.sex === selectedSex.name));
    attributeCount--;
  }

  const availableAccessories = accessories.filter(a => !a.sex || a.sex === selectedSex.name).filter(a => a.name !== 'selfieStick');
  for (let i = 0; i < attributeCount; i++) {
    if (availableAccessories.length > 0) {
      const accessory = selectRandom(availableAccessories);
      selectedAccessories.push(accessory);
      // Remove selected accessory to avoid duplicates
      availableAccessories.splice(availableAccessories.indexOf(accessory), 1);
    }
  }

  // Apply pre-processing conditions (filtering)
  selectedAccessories = filterExclusiveAccessories(selectedAccessories);

  // Apply conditions to face and accessories
  const modifiedFace = applyFaceConditions(selectedFace, selectedAccessories);
  const modifiedAccessories = applyAccessoryConditions(selectedAccessories, selectedHair);

  // Update traits state
  setTraits([
    {
      background: false,
      colors: [],
      type: 'human',
      sex: selectedSex.name,
      hair: selectedHair ? selectedHair.name : 'none',
      accessories: modifiedAccessories.map(acc => acc.name)
    }
  ]);

  // Main drawing logic
  draw(selectedSex, modifiedFace, selectedHair, modifiedAccessories, skinIndex);
};

export default Base;
