export const short_female = [
    { // hair
        color: [
            ['#51360c'], ['#000000'], ['#fff68e'], ['#e65700'], ['#ffffff']
        ],
        postProcessing: false,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [
            [ 8, 15],
            [ 7, 15],
            [ 7, 14],
            [ 6, 14],
            [ 6,  9],
            [ 7,  9],
            [ 7,  8],
            [ 8,  8],
            [ 8,  7],
            [ 9,  7],
            [ 9,  6],

            [14,  6],
            [14,  7],
            [15,  7],
            [15,  8],
            [16,  8],
            [16,  9],
            [17,  9],
            [17, 14],
            [15, 14],
            [15, 15],
            [16, 15],
            [16, 14],
            [16, 10],
            [14, 10],
            [14, 11],
            [12, 11],
            [12, 12],
            [13, 12],
            [13, 10],
            [ 9, 10],
            [ 9, 11],
            [ 8, 11],
            [ 8, 12],
            [ 7, 12],
            [ 7, 14],
            [ 8, 14],
        ],
        levy: true,
        levyCoords: [
            [ 8, 15],
            [ 7, 15],
            [ 7, 14],
            [ 6, 14],
            [ 6,  9],
            [ 7,  9],
            [ 7,  8],
            [ 8,  8],
            [ 8,  7],
            [ 9,  7],
            [ 9,  6],

            [14,  6],
            [14,  7],
            [15,  7],
            [15,  8],
            [16,  8],
            [16,  9],
            [17,  9],
            [17, 14],
            [15, 14],
            [15, 15],
            [16, 15],
            [16, 14],
            [16, 10],
            [14, 10],
            [14, 11],
            [12, 11],
            [12, 12],
            [13, 12],
            [13, 10],
            [ 9, 10],
            [ 9, 11],
            [ 8, 11],
            [ 8, 12],
            [ 7, 12],
            [ 7, 14],
            [ 8, 14],
        ]          
    }
]
