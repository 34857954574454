import { drawCoordinates } from '../utils/Draw';

import { punk } from '../assets/silhouettes/seedphrase.js';

const drawSilhouette = (p, cellSize, traits, setTraits, brushSize = 1) => {
  console.log(brushSize)
  drawCoordinates(punk, p, cellSize, 'brush', brushSize);
};

export default drawSilhouette;
