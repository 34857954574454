// contexts/P5Context.js
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import p5 from 'p5';

import mainDraw from '../sketches/Grid';
// import drawBase from '../sketches/Base';

import Playground from '../sketches/00_Playground';
import SelfieStick from '../sketches/01_SelfieStick';
import Tramp from '../sketches/03_Tramp';
import Alien from '../sketches/04_Alien';
import LadyBoy from '../sketches/05_LadyBoy';
import JosephAlbers from '../sketches/06_JosefAlbers';

import drawSilhouette from '../sketches/Silhouette';
import drawPFP from '../sketches/PFP';

const P5Context = createContext();

export const useP5Context = () => useContext(P5Context);

export const P5Provider = ({ children }) => {
  const p5ContainerRef = useRef(null);
  const [p5Instance, setP5Instance] = useState(null);
  const drawMethods = [
    // Playground,
    mainDraw,

    SelfieStick,
    Tramp,
    Alien,
    LadyBoy,

    drawSilhouette
  ];
  const [drawMethod, setDrawMethod] = useState(() => drawMethods[0]);
  const [traits, setTraits] = useState([{background: false, colors: []}]);

  const [settings, setSettings] = useState({
    fps: 1,
    noise: 25,
    traits: traits,
    debug: false,
  });

  const [brush, setBrush] = useState({
    value: 0
  });

  const [brushSize, setBrushSize] = useState(null);

  const [grain, setGrain] = useState({
    value: 0
  });


  const updateSetting = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  return (
    <P5Context.Provider value={{ 
      p5Instance, p5ContainerRef, 
      drawMethod, setDrawMethod, drawMethods, 
      settings, updateSetting,
      traits, setTraits,
      brushSize, setBrushSize,
       }}>
      {children}
    </P5Context.Provider>
  );
};
