export const punk = [
    { // right
        color: [
            ['#000000']
        ],
        coords: [
            [6, 24],
            [6, 21],
            [5, 21],
            [5, 18],
            [6, 18],
            [6, 16],
            [5, 16],
            [5, 15],
            [4, 15],
            [4, 14],
            [5, 14],
            [5, 12],
            [6, 12],
            [6, 9],
            [4, 9],
            [4, 8],
            [5, 8],
            [5, 7],
            [6, 7],
            [6, 2],
            [7, 2],
            [7, 1],
            [16, 1],
            [16, 2],
            [17, 2],
            [17, 7],
            [18, 7],
            [18, 8],
            [19, 8],
            [19, 9],
            [17, 9],
            [17, 17],
            [20, 17],
            [20, 18],
            [21, 18],
            [21, 19],
            [20, 19],
            [20, 20],
            [18, 20],
            [18, 22],
            [17, 22],
            [17, 23],
            [16, 23],
            [16, 24],
            [6, 24]
        ]
    },
    { // right
        color: [
            ['#000000']
        ],
        coords: [
            [19.5, 10],
            [19.5, 16],
        ]
    }
]