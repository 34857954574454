export const alien_female = [
    { // edges
        color: [
            ['#000']
        ],
        coords: [ 
        [ 8, 24],
        [ 8, 19],
        [ 7, 19],
        [ 7, 15],
        [ 6, 15],
        [ 6, 13],
        [ 5, 13],      
        [ 5, 11],
        [ 7, 11],
        [ 7,  9],
        [ 8,  9],
        [ 8,  8],
        [ 9,  8],
        [ 9,  7],
        [15,  7],
        [15,  8],
        [16,  8],
        [16,  9],
        [17,  9],
        [17, 19],
        [16, 19],
        [16, 20],
        [15, 20],
        [15, 21],
        [15, 21],
        [14, 21],
        [14, 22],
        [13, 22],
        [13, 24]
        ]
    },
    { // skin
        color: [
            ['#c8fbfb'],
        ],
        coords: [
        [ 9, 24],
        [ 9, 19],
        [ 8, 19],
        [ 8, 14],
        [ 7, 14],
        [ 7, 13],
        [ 6, 13],
        [ 6, 12],
        [ 8, 12],
        [ 8,  9],
        [ 9,  9],
        [ 9,  8],
        [15,  8],
        [15,  9],
        [16,  9],
        [16, 19],
        [15, 19],
        [15, 20],
        [14, 20],
        [14, 21],
        [11, 21],
        [11, 20],
        [10, 20],
        [10, 21],
        [11, 21],
        [11, 22],
        [12, 22],
        [12, 24]
        ] 
    },
    { // mouth
        color: [
            ['#000']
        ],
        coords: [ 
        [ 11, 18],
        [ 14, 18],
        [ 14, 19],
        [ 11, 19]
        ]
    },
    { // nose
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 12, 15],
        [ 13, 15],
        [ 13, 17],
        [ 12, 17]
        ]
    },

    { // eyeBall right
        color: [
            ['#000']
        ],
        coords: [ 
        [  9, 13],
        [ 10, 13],
        [ 10, 14],
        [  9, 14]
        ]
    },
    { // eye right
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 10, 13],
        [ 11, 13],
        [ 11, 14],
        [ 10, 14]
        ]
    },
    { // eyeTop right
        color: [
            ['#75bdbd']
        ],
        coords: [ 
        [ 9, 12],
        [10, 12],
        [10, 13],
        [ 9, 13]
        ]
    },
    { // eyeTop right _
        color: [
            ['#000']
        ],
        coords: [ 
        [10, 12],
        [11, 12],
        [11, 13],
        [10, 13]
        ]
    },

    { // eyeBall left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 14, 13],
        [ 15, 13],
        [ 15, 14],
        [ 14, 14]
        ]
    },
    { // eye left
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 15, 13],
        [ 16, 13],
        [ 16, 14],
        [ 15, 14]
        ]
    },
    { // eyeTop left
        color: [
            ['#75bdbd']
        ],
        coords: [ 
        [ 14, 12],
        [ 15, 12],
        [ 15, 13],
        [ 14, 13]
        ]
    },
    { // eyeTop left _
        color: [
            ['#000']
        ],
        coords: [ 
        [ 15, 12],
        [ 16, 12],
        [ 16, 13],
        [ 15, 13]
        ]
    },
    {
        // ear
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 7, 12],
        [ 8, 12],
        [ 8, 13],
        [ 7, 13]
        ]
    }
  ]