export const male = [
    { // edges
        color: [
            ['#000']
        ],
        coords: [ 
        [ 6, 24],
        [ 6, 15],
        [ 5, 15],
        [ 5, 12],
        [ 6, 12],
        [ 6,  7],
        [ 7,  7],
        [ 7,  6],
        [ 8,  6],
        [ 8,  5],
        [15,  5],
        [15,  6],
        [16,  6],
        [16,  7],
        [17,  7],
        [17, 20],
        [16, 20],
        [16, 21],
        [15, 21],
        [15, 22],
        [11, 22],
        [11, 24]
        ]
    },
    { // skin
        color: [
            ['#ebd9d9', '#c9b3b5', '#a48c8d', '#fff', '#000000'],
            ['#dbb17f', '#d29e62', '#a5702d', '#e7cba9', '#000000'], ['#ae8b61', '#a77c47', '#865920', '#b69f82', '#000000'], ['#713f1e', '#733709', '#562400', '#8b532c', '#000000']
        ],
        coords: [
        [ 7, 24],
        [ 7, 14],
        [ 6, 14],
        [ 6, 12],
        [ 7, 12],
        [ 7,  7],
        [ 8,  7],
        [ 8,  6],
        [ 9,  6],
        [15,  6],
        [15,  7],
        [16,  7],
        [16, 20],
        [15, 20],
        [15, 21],
        [10, 21],
        [10, 24]
        ] 
    }
  ]