

export const stringy_hair_male = [
    {
    color: [
        ['#000000']
    ],
    coords: [ 
    [ 9, 5],
    [ 10, 5],
    [ 10, 8],
    [  8, 8],
    [ 8, 10],
    [ 9, 10],
    ],
    levy: true,
        levyCoords: [
            [ 9, 5],
            [ 10, 5],
            [ 10, 8],
            [  8, 8],
            [ 8, 10],
            [ 9, 10],
        ]   
},
{
    color: [
        ['#000000']
    ],
    coords: [ 
    [ 11, 5],
    [ 12, 5],
    [ 12, 8],
    [ 10, 8],
    [ 10, 9],
    [12, 9],
    [12, 10],
    [11, 10]
    ],
    levy: true,
    levyCoords: [
        [ 11, 5],
        [ 12, 5],
        [ 12, 8],
        [ 10, 8],
        [ 10, 9],
        [12, 9],
        [12, 10],
        [11, 10]
    ]   
},
{
    color: [
        ['#000000']
    ],
    coords: [ 
    [ 13, 5],
    [ 14, 5],
    [ 14, 10],
    [15, 10],
    [15, 8],
    [ 13, 8]
    ],
    levy: true,
    levyCoords: [
        [ 13, 5],
        [ 14, 5],
        [ 14, 10],
        [15, 10],
        [15, 8],
        [ 13, 8]
    ]   
}
]