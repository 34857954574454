export const female = [
  { // edges
      color: [
          ['#000']
      ],
      coords: [ 
      [ 8, 24],
      [ 8, 19],
      [ 7, 19],
      [ 7, 15],
      [ 6, 15],      
      [ 6, 12],
      [ 7, 12],
      [ 7,  9],
      [ 8,  9],
      [ 8,  8],
      [ 9,  8],
      [ 9,  7],
      [15,  7],
      [15,  8],
      [16,  8],
      [16,  9],
      [17,  9],
      [17, 19],
      [16, 19],
      [16, 20],
      [15, 20],
      [15, 21],
      [15, 21],
      [14, 21],
      [14, 22],
      [13, 22],
      [13, 24]
      ]
  },
  { // skin
      color: [
          ['#ebd9d9', '#c9b3b5', '#a48c8d', '#fff', '#711010'], 
          ['#dbb17f', '#d29e62', '#a5702d', '#e7cba9', '#711010'], ['#ae8b61', '#a77c47', '#865920', '#b69f82', '#5f1d09'], ['#713f1e', '#733709', '#562400', '#8b532c', '#4a1201']
      ],
      coords: [
      [ 9, 24],
      [ 9, 19],
      [ 8, 19],
      [ 8, 14],
      [ 7, 14],
      [ 7, 12],
      [ 8, 12],
      [ 8,  9],
      [ 9,  9],
      [ 9,  8],
      [15,  8],
      [15,  9],
      [16,  9],
      [16, 19],
      [15, 19],
      [15, 20],
      [14, 20],
      [14, 21],
      [11, 21],
      [11, 20],
      [10, 20],
      [10, 21],
      [11, 21],
      [11, 22],
      [12, 22],
      [12, 24]
      ] 
  }
]