export const wild_hair_white_female = [
    { // hair
        color: [
           ['#ffffff']
        ],
        postProcessing: false,
        // colors: [
        //     ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        // ],
        coords: [

      [ 7, 17],
      [ 4, 17],
      [ 4, 18],
      [ 5, 18],
      [ 5, 15],
      [ 3, 15],
      [ 3, 14],
      [ 4, 14],
      [ 4, 13],
      [ 6, 13],
      [ 6, 14],
      [ 5, 14],
      [ 5, 11],
      [ 6, 11],
      [ 6, 12],
      [ 4, 12],
      [ 4, 11],
      [ 5, 11],
      [ 5, 10],
      [ 6, 10],
      [ 6,  9],
      [ 5,  9],
      [ 5,  8],
      [ 7,  8],
      [ 7,  6],
      [ 8,  6],
      [ 8,  7],
      [ 9,  7],
      [ 9,  6],
      [10,  6],
      [10,  5],
      [14,  5],
      [14,  6],
      [16,  6],
      [16,  5],
      [15,  5],
      [15,  7],
      [16,  7],
      [16,  8],
      [18,  8],
      [18,  7],
      [17,  7],
      [17,  9],
      [18,  9],
      [18,  8],
      [19,  8],
      [19, 10],
      [18, 10],
      [18, 11],
      [19, 11],
      [19, 13],
      [18, 13],
      [18, 12],
      [20, 12],
      [20, 13],
      [19, 13],
      [19, 15],
      [20, 15],
      [20, 16],
      [18, 16],
      [18, 18],
      [19, 18],
      [19, 17],
      [17, 17],
      [17, 12,],
      [16, 12,],
      [16, 11,],
      [15, 11,],
      [15, 10],
      [13, 10],
      [13, 14],
      [14, 14],
      [14, 13],
      [12, 13],
      [12, 10],
      [10, 10],
      [10, 11],
      [11, 11],
      [11, 9],
      [10, 9],
      [10, 10],
      [9,10],
      [9,12],
      [8,12],
      [8,13],
      [7,13],





    //   [10, 10],

        ]     
    }
]
