export const bandana_female = [
    { // bandana
        color: [
            ['#1a43c8']
        ],
        coords: [
            [12, 10],
            [12, 11],
            [15, 11],
            [15, 10],
            [16, 10],
            [16,  9],
            [17,  9],
            [17,  7],
            [16,  7],
            [16,  6],
            [ 8,  6],
            [ 8,  7],
            [ 7,  7],
            [ 7,  8],
            [ 6,  8],
            [ 6,  9],
            [ 2,  9],
            [ 2, 10],
            [ 3, 10],
            [ 3, 13],
            [ 4, 13],
            [ 4, 12],
            [ 5, 12],
            [ 5, 11],
            [ 6, 11],
            [ 6, 10]
        ]
    },
    { // edges
        color: [
            ['#1637a4']
        ],
        coords: [
            [ 3,  9],
            [ 4,  9],
            [ 4, 11],
            [ 5, 11],
            [ 5,  9],
            [ 7,  9],
            [ 7,  7],
            [16,  7],
            [16,  9],
            [15,  9],
            [15, 10],
            [12, 10],
            [12,  9],
            [ 8,  9],
            [ 8, 10],
            [12, 10],
            [12, 11],
            [15, 11],
            [15, 10],
            [16, 10],
            [16,  9],
            [17,  9],
            [17,  7],
            [16,  7],
            [16,  6],
            [ 8,  6],
            [ 8,  8],
            [ 6,  8],
            [ 6, 10],
            [ 3, 10],
        ]
    },
    { // shades
        color: [
            ['#142c7c']
        ],
        coords: [
            [ 7,  9],
            [ 6,  9],
            [ 6, 11],
            [ 4, 11],
            [ 4, 12],
            [ 5, 12],
            [ 5, 10],
            [ 7, 10]
        ]
    },
  ]