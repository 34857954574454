// controls/Keypress.js
import { useEffect } from 'react';
import { useP5Context } from '../contexts/P5Context';

const Keypress = () => {
  const { drawMethods, setDrawMethod, toggleLoop } = useP5Context();

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key.toLowerCase();

      if (key === 'l') {
        toggleLoop();
      } else if (key === 'arrowright') {
        setDrawMethod((prevMethod) => {
          const currentIndex = drawMethods.indexOf(prevMethod);
          const nextIndex = (currentIndex + 1) % drawMethods.length;
          return drawMethods[nextIndex];
        });
      } else if (key === 'arrowleft') {
        setDrawMethod((prevMethod) => {
          const currentIndex = drawMethods.indexOf(prevMethod);
          const prevIndex = (currentIndex - 1 + drawMethods.length) % drawMethods.length;
          return drawMethods[prevIndex];
        });
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [drawMethods, setDrawMethod, toggleLoop]);

  return null;
};

export default Keypress;
