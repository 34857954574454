
import { debugPoints } from '../utils/Utils';
 
 export const drawCoordinates = (coords, p, cellSize, type, brushSize) => {
    coords.forEach((part) => {
      if (part && part.coords && Array.isArray(part.coords)) {
        part.coords.forEach((coord, index) => {
          if (index > 0) {
            const [prevX, prevY] = part.coords[index - 1];
            const [x, y] = coord;

            const startX = prevX * cellSize;
            const startY = prevY * cellSize;
            const endX = x * cellSize;
            const endY = y * cellSize;

            if(type === 'brush') {
                drawBrushStroke(p, startX, startY, endX, endY, brushSize);
            }

            if(type === 'line') {
              drawLine(p, startX, startY, endX, endY, brushSize);
          }

          }
        });
      } else {
        console.error("Invalid part or missing coords:", part);
      }
    });
  };

  export const drawLayer = (p, cellSize, coords, color, showDebug) => {
    p.fill(color);
    p.noStroke();
    p.beginShape();
    coords.forEach(([x, y]) => p.vertex(x * cellSize, y * cellSize));
    p.endShape(p.CLOSE);

    if (showDebug) {
      debugPoints(coords, cellSize, p);
    }
  };

  export const drawLine = (p, startX, startY, endX, endY, brushSize) => {
    const proportionalStrokeWeight = 0.69 * (p.width / 400);
    p.strokeWeight(proportionalStrokeWeight);
    p.stroke(0, 0, 0, 80);

    const lineLength = p.dist(startX, startY, endX, endY);
    const numSegments = Math.floor(lineLength / (proportionalStrokeWeight * 2));

    for (let i = 0; i < numSegments; i++) {
      const t = i / numSegments;
      const x = p.lerp(startX, endX, t);
      const y = p.lerp(startY, endY, t);
      const jitterX = p.random(-proportionalStrokeWeight, proportionalStrokeWeight);
      const jitterY = p.random(-proportionalStrokeWeight, proportionalStrokeWeight);
      p.line(x, y, x + jitterX, y + jitterY);
    }
  };

  export const drawBrushStroke = (p, startX, startY, endX, endY, brushSize) => {
    p.strokeWeight(brushSize);
    p.stroke(0, 0, 0, 80);

    const lineLength = p.dist(startX, startY, endX, endY);
    const numSegments = Math.floor(lineLength / (brushSize * 2));

    for (let i = 0; i < numSegments; i++) {
      const t = i / numSegments;
      const x = p.lerp(startX, endX, t);
      const y = p.lerp(startY, endY, t);
      const jitterX = p.random(-brushSize, brushSize);
      const jitterY = p.random(-brushSize, brushSize);
      p.line(x, y, x + jitterX, y + jitterY);
    }
  };