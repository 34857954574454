import { male_normal } from './male.js';
import { female_normal } from './female.js';
import { female_alien } from './female_alien.js';
import { blushing_female } from './blushing_female.js';

export const face = [
     { name: 'normal', type: 'human', sex: 'male', data: male_normal },
     { name: 'normal', type: 'human',  sex: 'female', data: female_normal },
     // { name: 'blushing', type: 'human',  sex: 'female', data: blushing_female },
     { name: 'normal', type: 'alien',  sex: 'female', data: female_alien },
];