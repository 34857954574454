import { noggles_male } from './noggles_male.js';
import { noggles_female } from './noggles_female.js';
import { cigarette } from './cigarette.js';
import { earring_female } from './earring_female.js';
import { selfie_stick } from './selfie_stick.js';
import { clown_nose_male } from './clown_male.js';
import { clown_nose_female } from './clown_female.js';
import { rosy_cheeks_female } from './rosy_cheeks_female.js';
import { mole_female } from './mole_female.js';

export const accessories = [
     { name: 'Noggles', sex: 'male', data: noggles_male },
     { name: 'Noggles', sex: 'female', data: noggles_female },

     { name: 'Cigarette', sex: 'male', data: cigarette },
     { name: 'Cigarette', sex: 'female', data: cigarette },

     { name: 'Clown Nose', sex: 'male', data: clown_nose_male},
     { name: 'Clown Nose', sex: 'female', data: clown_nose_female},

     { name: 'Earring', sex: 'female', data: earring_female, conditions: true },

     { name: 'Rosy Cheeks', sex: 'female', data: rosy_cheeks_female, conditions: true},

     { name: 'Mole', sex: 'female', data: mole_female, conditions: true},

     { name: 'Hot Lipstick', sex: 'female', conditions: true},
     { name: 'Dark Lipstick', sex: 'female', conditions: true},

     { name: 'selfieStick', sex: 'female', data: selfie_stick},
];