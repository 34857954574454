export const selfie_stick = [
    { // stick
        color: [
            ['#fff']
        ],
        coords: [ 
        [26, 23],
        [27, 23],
        [27, 20],
        [29, 20],
        [29, 16],
        [30, 16],
        [30, 18],
        [28, 18],
        [28, 22],
        [26, 22]
        ]
    },
    {
        color: [
            ['#DFDFDF']
        ],
        coords: [
            [30, 16],
            [31, 16],
            [31, 15],
            [30, 15]
        ]
    },
    { // phone
        color: [
            ['#fff']
        ],
        coords: [
            [27, 15],
            [34, 15],
            [34,  3],
            [27,  3]
        ]
    },
    {
        color: [
            ['#eee']
        ],
        postProcessing: true,
        coords: [
            [28, 14],
            [33, 14],
            [33,  4],
            [28,  4]
        ]
    },
    { // skin
        color: [
            ['#ebd9d9']
        ],
        coords: [ 
            [28,  4],
            [28, 13],
            [31, 13],
            [31, 14],
            [33, 14],
            [33,  4]
        ]
    },
    { // mouth
        color: [
            ['#C42110']
        ],
        postProcessing: true,
        colors: [
            [
            '#d22209',
            '#d31e14',
            '#ebd9d9',
            '#bd2d24',
            '#c5030e'
           ]
        ],
        coords: [ 
            [30, 12],
            [31, 12],
            [31, 11],
            [30, 11]
        ]
    },
    { // nose
        color: [
            ['#000000']
        ],
        coords: [ 
            [30, 10],
            [31, 10],
            [31,  9],
            [30,  9]
        ]
    },
    {
        color: [
            ['#FF8DBE']
        ],
        postProcessing: true,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [ 
            [28, 12],
            [29, 12],
            [29, 13],
            [28, 13]
        ]
    },
    {
        color: [
            ['#FF8DBE']
        ],
        postProcessing: true,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [ 
            [28,  4],
            [33,  4],
            [33,  5],
            [31,  5],
            [31,  6],
            [30,  6],
            [30,  5],
            [28,  5]
        ]
    },

    

    { // right eye
        color: [
            ['#c9b3b5']
        ],
        coords: [ 
            [28,  7],
            [29,  7],
            [29,  8],
            [28,  8]
        ]
    },
    { // right eyeBall
        color: [
            ['#000000']
        ],
        coords: [ 
            [29,  7],
            [30,  7],
            [30,  8],
            [29,  8]
        ]
    },
    { // right eyebrow
        color: [
            ['#a48c8d']
        ],
        coords: [ 
            [28,  7],
            [30,  7],
            [30,  6],
            [28,  6]
        ]
    },

    { // left eye
        color: [
            ['#c9b3b5']
        ],
        coords: [ 
            [31,  7],
            [32,  7],
            [32,  8],
            [31,  8]
        ]
    },
    { // left eyeBall
        color: [
            ['#000000']
        ],
        coords: [ 
            [32,  7],
            [33,  7],
            [33,  8],
            [32,  8]
        ]
    },
    { // left eyebrow
        color: [
            ['#a48c8d']
        ],
        coords: [ 
            [31,  6],
            [33,  6],
            [33,  7],
            [31,  7]
        ]
    },
  ]