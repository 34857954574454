export const pink_female = [
    { // hair
        color: [
            ['#FF8DBE']
        ],
        postProcessing: false,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [
            [ 9, 21],
            [ 7, 21],
            [ 7, 20],
            [ 6, 20],
            [ 6, 18],
            [ 5, 18],
            [ 5, 13],
            [ 6, 13],
            [ 6, 11],
            [ 7, 11],
            [ 7, 10],
            [17, 10],
            [17, 11],
            [18, 11],
            [18, 13],
            [19, 13],
            [19, 18],
            [18, 18],
            [18, 20],
            [17, 20],
            [17, 21],
            [15, 21],
            [15, 19],
            [16, 19],
            [16, 11],
            [14, 11],
            [14, 12],
            [13, 12],
            [13, 13],
            [12, 13],
            [12, 12],
            [11, 12],
            [11, 11],
            [ 9, 11],
            [ 9, 12],
            [ 8, 12],
            [ 8, 13],
            [ 7, 13],
            [ 7, 14],
            [ 8, 14],
            [ 8, 19],
            [ 9, 19]
        ]     
    },
    { // beanie
        color: [
            ['#000000']
        ],
        coords: [
            [ 7, 10],
            [17, 10],
            [17,  8],
            [16,  8],
            [16,  7],
            [15,  7],
            [15,  6],
            [ 9,  6],
            [ 9,  7],
            [ 8,  7],
            [ 8,  8],
            [ 7,  8]
        ]     
    },
    {
        color: [
            ['#ffffff']
        ],
        coords: [
            [11,  8],
            [13,  8],
            [13,  9],
            [12,  9],
            [12,  7],
            [11,  7]
        ]     
    }
]
