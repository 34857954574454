export const rosy_cheeks_female = [
    {
    color: [
        ['#d09c6e']
    ],
    coords: [ 
    [ 9, 15],
    [ 11, 15],
    [ 11, 16],
    [ 9, 16]
    ]
},
{
    color: [
        ['#d09c6e']
    ],
    coords: [ 
    [ 14, 15],
    [ 16, 15],
    [ 16, 16],
    [ 14, 16]
    ]
}
]