export const pink_male = [
    {
        color: [
            ['#FF8DBE']
        ],
        postProcessing: false,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [
            [ 7,  6],
            [ 7,  7],
            [ 6,  7],
            [ 6, 10],
            [ 5, 10],
            [ 5,  9],
            [ 4,  9],
            [ 4,  6],
            [ 5,  6],
            [ 5,  5],
            [ 6,  5],
            [ 6,  4],
            [ 8,  4],
            [ 8,  3],
            [16,  3],
            [16,  4],
            [17,  4],
            [17,  7],
            [18,  7],
            [18,  8],
            [17,  8],
            [17,  9],
            [16,  9],
            [16,  8],
            [15,  8],
            [15,  7],
            [14,  7],
            [14,  8],
            [13,  8],
            [13,  7],
            [12,  7],
            [12,  8],
            [ 9,  8],
            [ 9,  9],
            [ 8,  9],
            [ 8,  6]
        ],
        levy: true,
        levyCoords: [
            [ 6, 10],
            [ 5, 10],
            [ 5,  9],
            [ 4,  9],
            [ 4,  6],
            [ 5,  6],
            [ 5,  5],
            [ 6,  5],
            [ 6,  4],
            [ 8,  4],
            [ 8,  3],
            [16,  3],
            [16,  4],
            [17,  4],
            [17,  7],
            [18,  7],
            [18,  8],
            [17,  8],
            [17,  9],
            [16,  9],
            [16,  8],
            [15,  8],
            [15,  7],
            [14,  7],
            [14,  8],
            [13,  8],
            [13,  7],
            [12,  7],
            [12,  8],
            [ 9,  8],
            [ 9,  9],
            [ 8,  9],
            [ 8,  7]
        ]     
    }
]
