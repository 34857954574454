export const mole_female = [
    {
    part: 'mole',
    color: [
        ['#917656']
    ],
    coords: [ 
    [ 9, 16],
    [10, 16],
    [10, 17],
    [ 9, 17]
    ]
}
]