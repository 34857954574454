export const female_normal = [
    {
        part: 'mouth',
        color: [
            ['#000000']
        ],
        postProcessing: true,
        coords: [ 
        [ 11, 18],
        [ 14, 18],
        [ 14, 19],
        [ 11, 19]
        ]
    },
    { // nose
        color: [
            ['#000']
        ],
        coords: [ 
        [ 12, 16],
        [ 13, 16],
        [ 13, 17],
        [ 12, 17]
        ]
    },

    { // eyeBall right
        color: [
            ['#000']
        ],
        coords: [ 
        [  9, 13],
        [ 10, 13],
        [ 10, 14],
        [  9, 14]
        ]
    },
    { // eye right
        coords: [ 
        [ 10, 13],
        [ 11, 13],
        [ 11, 14],
        [ 10, 14]
        ]
    },
    { // eyeBrow right
        coords: [ 
        [ 9, 12],
        [11, 12],
        [11, 13],
        [ 9, 13]
        ]
    },

    { // eyeBall left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 14, 13],
        [ 15, 13],
        [ 15, 14],
        [ 14, 14]
        ]
    },
    { // eye left
        coords: [ 
        [ 15, 13],
        [ 16, 13],
        [ 16, 14],
        [ 15, 14]
        ]
    },
    { // eyeBrow left
        coords: [ 
        [ 14, 12],
        [ 16, 12],
        [ 16, 13],
        [ 14, 13]
        ]
    },
    {
        // shade
        coords: [ 
        [ 9,  9],
        [10,  9],
        [10, 10],
        [ 9, 10]
        ]
    }
  ]