import Punk from './Punk';
import { extractUniqueColors } from '../utils/Colors';
import { conditions, applyAccessoryConditions, filterExclusiveAccessories } from '../utils/conditions'; // Import conditions and filtering

import { sex } from '../assets/traits/sex/_index.js';
import { face } from '../assets/traits/face/_index.js';
import { hair } from '../assets/traits/hair/_index.js';
import { accessories } from '../assets/traits/accessories/_index.js';

// Define trait configuration
const traitsConfig = {
  type: 'alien',
  sex: 'female',
  hair: 'Bandana',
  accessories: [],
  skinTone: null,
  face: false
};

const Alien = (p, cellSize, userTraits, setTraits, debug = false) => {
  const { draw, selectRandom, applyFaceConditions } = Punk(p, cellSize, userTraits, setTraits, debug);
  const uniqueColors = extractUniqueColors(p);

  // Select the sex based on traitsConfig
  const sexOptions = sex.filter(s => s.type === traitsConfig.type);
  const selectedSex = traitsConfig.sex ? sexOptions.find(s => s.name === traitsConfig.sex) : selectRandom(sexOptions);

  if (!selectedSex) {
    console.error(`No sex data available for ${traitsConfig.sex || 'any'} ${traitsConfig.type}.`);
    return;
  }

  // Select the skin tone
  const skinIndex = traitsConfig.skinTone !== null ? traitsConfig.skinTone : Math.floor(Math.random() * selectedSex.data[1].color.length);
  
  // Select the face options
  const faceOptions = face.filter(f => f.sex === selectedSex.name && f.type === selectedSex.type);
  const selectedFace = faceOptions.length > 0 ? selectRandom(faceOptions) : { name: 'defaultFace', data: [] };

  // Select the hair
  const selectedHair = hair.find(h => h.name === traitsConfig.hair) || { name: 'none', data: null };

  // Select the accessories
  const selectedAccessories = traitsConfig.accessories.map(accName => {
    const accessory = accessories.find(a => a.name === accName && (!a.sex || a.sex === selectedSex.name));
    return accessory || { name: accName, data: null };
  });

  const filteredAccessories = filterExclusiveAccessories(selectedAccessories);

  const modifiedFace = applyFaceConditions(selectedFace, filteredAccessories);
  const modifiedAccessories = applyAccessoryConditions(filteredAccessories, selectedHair);

  setTraits([
    {
      background: false,
      colors: [],
      type: traitsConfig.type,
      sex: selectedSex.name,
      hair: selectedHair.name,
      accessories: modifiedAccessories.map(acc => acc.name)
    }
  ]);

  // Main drawing logic
  draw(selectedSex, modifiedFace, selectedHair, modifiedAccessories, skinIndex);
};

export default Alien;
