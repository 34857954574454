import p5 from 'p5';

export function setupLevyFlightWithSkinCoords(p, part, cellSize, color) {

    let skinVectors = part.levyCoords.map(coord => p.createVector(coord[0] * cellSize, coord[1] * cellSize));
    let points = [];
    let currentPoint = skinVectors[0];
    points.push(currentPoint);

    let arrayLock = [[6, 24], [11, 24], [11, 22], [15, 22]]; // Locked coordinates

    function isInLockedArray(point, lockedArray) {
        return lockedArray.some(lockedPoint =>
            Math.round(lockedPoint[0]) === Math.round(point.x / cellSize) && 
            Math.round(lockedPoint[1]) === Math.round(point.y / cellSize)
        );
    }

    for (let i = 1; i < skinVectors.length; i++) {
        let nextPoint = skinVectors[i];
        let gridDistance = parseInt(p5.Vector.dist(currentPoint, nextPoint) / cellSize);

        if (gridDistance === 1 && nextPoint.y / cellSize <= 19 && nextPoint.x / cellSize <= 13) {
            let steps = p.random(p.random(2, 6), p.random(5, 10));
            for (let step = 0; step < steps; step++) {
                let randomDirection = p.random(p.TWO_PI);
                let stepLength = p.random(1, p.random(p.random(1.5, 3), p.random(4, 6)));
                let target = p5.Vector.fromAngle(randomDirection).mult(stepLength).add(currentPoint);

                target.x = p.constrain(target.x, 0, p.width);
                target.y = p.constrain(target.y, 0, p.height);

                if (!isInLockedArray(target, arrayLock)) {
                    points.push(target);
                }
                currentPoint = target;
            }
        } else if (gridDistance >= 2 && gridDistance <= 5) {
            if (p.random(1, 2) !== 1) {
                let steps = p.random(6.9, 35);
                for (let step = 0; step < steps; step++) {
                    let randomDirection = p.random(p.TWO_PI);
                    let stepLength = p.random(1, 2);
                    let target = p5.Vector.fromAngle(randomDirection).mult(stepLength).add(currentPoint);

                    target.x = p.constrain(target.x, 0, p.width);
                    target.y = p.constrain(target.y, 0, p.height);

                    if (!isInLockedArray(target, arrayLock)) {
                        points.push(target);
                    }
                    currentPoint = target;

                    if (step % 30 === 0) {
                        currentPoint = p5.Vector.lerp(currentPoint, nextPoint, 0.05);
                    }
                }
            }
        }

        points.push(nextPoint);
        currentPoint = nextPoint;
    }

    drawLevyFlight(p, points, part.levyColor || color, cellSize);
}

export function drawLevyFlight(p, points, color, cellSize) {
    let alphaColor = p.color(color);
    alphaColor.setAlpha(102); // 40% alpha

    p.strokeWeight(0.69);
    p.stroke(alphaColor);

    p.noFill();
    const rectSize = cellSize / 48;
    for (let i = 0; i < points.length - 1; i++) {
        p.line(points[i].x, points[i].y, points[i + 1].x, points[i + 1].y);
    }
}
