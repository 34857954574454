import React, { useEffect, useRef } from 'react';
import { useP5Context } from '../contexts/P5Context';
import { Pane } from 'tweakpane';
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';

const Tweak = () => {
  const { settings, updateSetting, traits } = useP5Context();
  const paneRef = useRef(null);

  useEffect(() => {
    if (!paneRef.current) {
      paneRef.current = new Pane({ container: document.body });

      paneRef.current.registerPlugin(EssentialsPlugin);

      const fpsGraph = paneRef.current.addBlade({
        view: 'fpsgraph',
        label: 'FPS',
        rows: 2,
      });

      render();

      function render() {
        fpsGraph.begin();
        fpsGraph.end();
        requestAnimationFrame(render);
      }

      const traitsFolder = paneRef.current.addFolder({
        title: 'TRAITS',
        expanded: false,
      });

      updateTraitsDisplay(traitsFolder, traits);



      const settingsFolder = paneRef.current.addFolder({
        title: 'SETTINGS',
        expanded: true,
      });

      settingsFolder.addBinding(settings, 'fps', {
        label: 'FPS',
        min: 0,
        max: 5,
        step: 1,
      }).on('change', (ev) => {
        updateSetting('fps', parseInt(ev.value, 10)); // Ensure value is parsed as an integer
      });

      const brushFolder = settingsFolder.addFolder({
        title: 'BRUSH',
        expanded: false,
      });

      const grainFolder = settingsFolder.addFolder({
        title: 'GRAIN',
        expanded: false,
      });

      grainFolder.addBinding(settings, 'noise', {
        label: 'NOISE',
        min: 0,
        max: 100,
        step: 1,
      }).on('change', (ev) => {
        updateSetting('noise', parseInt(ev.value, 10)); // Ensure value is parsed as an integer
      });

      settingsFolder.addBinding(settings, 'debug', { label: 'DEBUG' }).on('change', (ev) => {
        updateSetting('debug', ev.value);
      });

      const exportFolder = paneRef.current.addFolder({
        title: 'EXPORT',
        expanded: false,
      });

      exportFolder.addButton({
        title: 'EXPORT',
        label: 'STANDALONE',
      }).on('click', () => {
        console.log('Exporting...');
        // Implement export functionality here
      });

      // paneRef.current.addBlade({
      //   view: 'buttongrid',
      //   size: [3, 3],
      //   cells: (x, y) => ({
      //     title: [
      //       ['JPEG', 'GIF']
      //     ][y][x],
      //   }),
      //   label: 'Format',
      // }).on('click', () => {
      //   console.log('click');
      // });

    }

    return () => {
      if (paneRef.current) {
        paneRef.current.dispose();
        paneRef.current = null;
      }
    };
  }, []);

  // settings, updateSetting, traits

  const updateTraitsDisplay = (traitsFolder, traits) => {
    traitsFolder.children.forEach(child => child.dispose());

    traits.forEach((trait) => {
      Object.keys(trait).forEach((key) => {
        if (key === 'background' || key === 'colors') {
          return;
        }

        if (key === 'hair' || key === 'accessories') {
          // Combine hair and accessories into "Attributes"
          const attributes = key === 'hair' ? [trait[key]] : trait[key];
          attributes.forEach((attribute, index) => {
            if (attribute !== 'none') {
              if(index === 0) {
                traitsFolder.addBinding({ [attribute]: attribute }, attribute, { label: 'ATTRIBUTES' });
              } else {
                traitsFolder.addBinding({ [attribute]: attribute }, attribute, { label: '' });
              }
            }
          });
        } else if (typeof trait[key] === 'boolean' || typeof trait[key] === 'number' || typeof trait[key] === 'string') {
          traitsFolder.addBinding(trait, key, { label: key.toUpperCase() });
        }
      });
    });
  };

  useEffect(() => {
    if (paneRef.current) {
      const traitsFolder = paneRef.current.children.find(child => child.title === 'TRAITS');
      if (traitsFolder) {
        updateTraitsDisplay(traitsFolder, traits);
      }
    }
  }, [traits]);

  return null;
};

export default Tweak;
