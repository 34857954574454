// utils/Utils.js
let noiseApplied = false; // Control flag for noise application

export const applyNoiseEffectToCanvas = (p, noiseLevel = 25) => {
  if (!p) {
    return;
  }
  p.loadPixels();
  for (let y = 0; y < p.height; y++) {
    for (let x = 0; x < p.width; x++) {
      let index = (x + y * p.width) * 4;
      p.pixels[index] = p.constrain(p.pixels[index] + p.random(-noiseLevel, noiseLevel), 0, 255);     // Red
      p.pixels[index + 1] = p.constrain(p.pixels[index + 1] + p.random(-noiseLevel, noiseLevel), 0, 255); // Green
      p.pixels[index + 2] = p.constrain(p.pixels[index + 2] + p.random(-noiseLevel, noiseLevel), 0, 255); // Blue
    }
  }
  p.updatePixels();
};


export function drawBackground(p, cellSize, applyPoisson = false, colors) {
  // Start by setting up the 2D drawing context within the WebGL mode
  p.push();
  p.resetMatrix();
  p.ortho();

  // Fill the entire canvas with the background color
  p.fill('#eeeeee');
  p.noStroke();
  p.rect(-p.width / 2, -p.height / 2, p.width, p.height);

  if (applyPoisson) {
    // Define the shape covering the entire canvas
    const shapeCoords = [
      [0, 0],
      [p.width / cellSize, 0],
      [p.width / cellSize, p.height / cellSize],
      [0, p.height / cellSize]
    ];
    poissonDiscWithinShape(p, shapeCoords, 21, 30, cellSize, colors);
  }
}


export function poissonDiscWithinShape(p, shapeCoords, r, k, cellSize, colorArray) {

  let active = [];
  let points = [];
  colorArray = convertColorsToRgba(colorArray);

  // console.log(colorArray)

  const boundingBox = getBoundingBox(shapeCoords);
  const initialPoint = getInitialPoint(p, cellSize, boundingBox);

  if (!isPointInPolygon([initialPoint.x / cellSize, initialPoint.y / cellSize], shapeCoords)) return;

  active.push(initialPoint);
  points.push({ point: initialPoint, color: p.random(colorArray) });

  while (active.length > 0) {
    const randIndex = Math.floor(p.random(active.length));
    const pos = active[randIndex];
    let found = false;

    for (let n = 0; n < k; n++) {
      const sample = getRandomSample(p, pos, r);
      const sampleCoords = [sample.x / cellSize, sample.y / cellSize];

      if (isSampleValid(cellSize, sample, boundingBox, shapeCoords, points, r)) {
        found = true;
        active.push(sample);
        points.push({ point: sample, color: p.random(colorArray) });
        break;
      }
    }

    if (!found) active.splice(randIndex, 1);
  }

  drawPoints(p, points, cellSize);
}

function getBoundingBox(coords) {
  return {
    minX: Math.min(...coords.map(([x]) => x)),
    maxX: Math.max(...coords.map(([x]) => x)),
    minY: Math.min(...coords.map(([, y]) => y)),
    maxY: Math.max(...coords.map(([, y]) => y)),
  };
}

function getInitialPoint(p, cellSize, boundingBox) {
  return p.createVector(p.random(boundingBox.minX, boundingBox.maxX) * cellSize, p.random(boundingBox.minY, boundingBox.maxY) * cellSize);
}

function getRandomSample(p, pos, r) {
  const sample = p.createVector(p.random(-1, 1), p.random(-1, 1)).mult(p.random(r, 2 * r)).add(pos);
  return sample;
}

function isSampleValid(cellSize, sample, boundingBox, shapeCoords, points, r) {
  const sampleCoords = [sample.x / cellSize, sample.y / cellSize];
  return (
    sampleCoords[0] >= boundingBox.minX && sampleCoords[0] <= boundingBox.maxX &&
    sampleCoords[1] >= boundingBox.minY && sampleCoords[1] <= boundingBox.maxY &&
    isPointInPolygon(sampleCoords, shapeCoords) &&
    !points.some(({ point }) => sample.dist(point) < r)
  );
}

function drawPoints(p, points, cellSize) {
  points.forEach(({ point, color }) => {
    p.fill(color);
    p.noStroke();
    p.rect(point.x - cellSize / 48, point.y - cellSize / 48, cellSize / 24, cellSize / 24);
  });
}

function isPointInPolygon(point, polygon) {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, yi] = polygon[i];
    const [xj, yj] = polygon[j];
    const intersect = ((yi > point[1]) !== (yj > point[1])) && (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
    if (intersect) isInside = !isInside;
  }
  return isInside;
}

export const resetNoiseFlag = () => {
  noiseApplied = false;
};

// ***

export const selectRandom = (array) => {
  return array.length > 0 ? array[Math.floor(Math.random() * array.length)] : { name: 'default', data: [] };
};

// DEBUG

export function debugPoints(coords, cellSize, p) {
  coords.forEach(([x, y]) => {
    const px = x * cellSize;
    const py = y * cellSize;
    p.fill(0, 255, 0); // Green for visibility
    p.ellipse(px, py, 5, 5);
    p.fill(0);
    p.textSize(10);
    p.text(`[${x},${y}]`, px + 3, py + 1.8);
  });
}

// COLORS

export function hexToRgba(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = 255; // Alpha channel is fully opaque for HEX colors
  return [r, g, b, a];
}

export function convertColorsToRgba(colorArray) {
  return colorArray.map(color => /^#([0-9A-F]{3}){1,2}$/i.test(color) ? hexToRgba(color) : color);
}
