export const female_alien = [
    { // mouth
        color: [
            ['#000']
        ],
        coords: [ 
        [ 11, 18],
        [ 14, 18],
        [ 14, 19],
        [ 11, 19]
        ]
    },
    { // nose
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 12, 15],
        [ 13, 15],
        [ 13, 17],
        [ 12, 17]
        ]
    },

    { // eyeBall right
        color: [
            ['#000']
        ],
        coords: [ 
        [  9, 13],
        [ 10, 13],
        [ 10, 14],
        [  9, 14]
        ]
    },
    { // eye right
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 10, 13],
        [ 11, 13],
        [ 11, 14],
        [ 10, 14]
        ]
    },
    { // eyeTop right
        color: [
            ['#75bdbd']
        ],
        coords: [ 
        [ 9, 12],
        [10, 12],
        [10, 13],
        [ 9, 13]
        ]
    },
    { // eyeTop right _
        color: [
            ['#000']
        ],
        coords: [ 
        [10, 12],
        [11, 12],
        [11, 13],
        [10, 13]
        ]
    },

    { // eyeBall left
        color: [
            ['#000']
        ],
        coords: [ 
        [ 14, 13],
        [ 15, 13],
        [ 15, 14],
        [ 14, 14]
        ]
    },
    { // eye left
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 15, 13],
        [ 16, 13],
        [ 16, 14],
        [ 15, 14]
        ]
    },
    { // eyeTop left
        color: [
            ['#75bdbd']
        ],
        coords: [ 
        [ 14, 12],
        [ 15, 12],
        [ 15, 13],
        [ 14, 13]
        ]
    },
    { // eyeTop left _
        color: [
            ['#000']
        ],
        coords: [ 
        [ 15, 12],
        [ 16, 12],
        [ 16, 13],
        [ 15, 13]
        ]
    },
    {
        // ear
        color: [
            ['#9be0e0']
        ],
        coords: [ 
        [ 7, 12],
        [ 8, 12],
        [ 8, 13],
        [ 7, 13]
        ]
    }
  ]