import drawBase from './Base';

let traits = [];

export const setTraits = newTraits => {
  traits = newTraits;
};

export const getTraits = () => {
  return traits;
};

const drawGrid = (p, cellSize, traits, setTraits, debug = false, canSubdivide = true) => {
  const gridSize = 24;
  const subGridSize = gridSize / 2; // Each subgrid is 12x12

  const drawInSubgrid = (xOffset, yOffset) => {
    p.push();
    p.translate(xOffset * cellSize, yOffset * cellSize);
    // console.log(`Drawing subgrid at offset (${xOffset}, ${yOffset})`);
    // p.stroke(0); // Draw square borders
    // p.noFill();
    // p.rect(0, 0, 12 * cellSize, 12 * cellSize); // Draw the square for visualization

    if (canSubdivide && Math.random() < 0.33) { // 33% chance to redivide, but only once
      const smallerCellSize = cellSize / 2;
      // Subdivide into four smaller grids without further subdivision
      drawGrid(p, smallerCellSize, traits, setTraits, debug, false);
    } else {
      drawBase(p, cellSize / 2, traits, setTraits, debug);
    }

    p.pop();
  };

  // Draw in four subgrids
  drawInSubgrid(0, 0); // Top-left
  drawInSubgrid(subGridSize, 0); // Top-right
  drawInSubgrid(0, subGridSize); // Bottom-left
  drawInSubgrid(subGridSize, subGridSize); // Bottom-right
};

const mainDraw = (p, cellSize, debug = false) => {
  let traits = getTraits();
  drawGrid(p, cellSize, traits, setTraits, debug);
};

export default mainDraw;
