export const straight_hair_female = [
    { // hair
        color: [
            ['#51360c'], ['#000000'], ['#fff68e'], ['#e65700'], ['#ffffff']
        ],
        postProcessing: false,
        colors: [
            ['#FF638D', '#ff8db2', '#d596a6', '#FF8DBE', '#FF8DBE' ]
        ],
        coords: [
            [ 8, 23],
            [ 5, 23],
            [ 5, 13],
            [ 6, 13],
            [ 6, 10],
            [ 7, 10],
            [ 7,  9],
            [ 8,  9],
            [ 8,  8],
            [ 9,  8],
            [ 9,  7],
            [15,  7],
            [15,  8],
            [16,  8],
            [16,  9],
            [17,  9],
            [17, 22],
            [16, 22],
            [16, 23],
            [13, 23],
            [13, 22],
            [14, 22],
            [14, 21],
            [15, 21],
            [15, 20],
            [16, 20],
            [16, 11],
            [15, 11],
            [15, 10],
            [10, 10],
            [10, 11],
            [ 9, 11],
            [ 9, 13],
            [ 8, 13],
        ]     
    }
]
