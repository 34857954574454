import { setupLevyFlightWithSkinCoords } from './Levy';

export function filterExclusiveAccessories(accessories) {
  const exclusiveGroups = [
    ['Hot Lipstick', 'Dark Lipstick', 'Purple Lipstick'],
    ['Rosy Cheeks', 'Mole'],
    ['Rosy Cheeks', 'Noggles'],
  ];

  return accessories.filter((accessory, index) => {
    for (const group of exclusiveGroups) {
      if (group.includes(accessory.name)) {
        return !accessories.some((otherAccessory, otherIndex) => 
          group.includes(otherAccessory.name) && otherIndex !== index);
      }
    }
    return true;
  });
}

export const conditions = {
  Earring: (accessoryData, selectedHair) => {
    if (selectedHair && selectedHair.length === 'long') {
      return [accessoryData[0]];
    }
    return accessoryData;
  },
  Noggles: (accessoryData) => {
    const colors = ['#000000', '#f3322c', '#FF638D', '#1d2b53', '#7e2553', '#008751', '#ab5236', '#5f574f', '#c2c3c7', '#fff1e8', '#ffa300', '#ffff27', '#00e756', '#29adff', '#29adff'];
    return accessoryData.map((trait, index) => {
      if (index === 0 && trait.part === 'noggles') {
        return {
          ...trait,
          color: [colors[Math.floor(Math.random() * colors.length)]]
        };
      }
      return trait;
    });
  },
  Cigarette: (accessoryData) => {
    let rdmOffset = Math.floor(Math.random() * 3);
    let adjustedData = accessoryData.map((trait) => {
        let adjustedCoords = trait.coords.map(([x, y]) => {
            if (trait.color && (trait.color[0] === '#c6c6c6' || trait.color[0] === '#e25b26' || trait.color[0] === '#000')) {
                return [x >= 15 ? x + rdmOffset : x, y];
            }
            return [x, y];
        });
        return {
            ...trait,
            coords: adjustedCoords,
        };
    });

    // Calculate Levy flight coordinates based on the last part's adjusted x-coordinate
    let lastPart = adjustedData[adjustedData.length - 1];
    const levyCoords = [];
    const startX = 15 + rdmOffset + 0.5; // Adjusted x-coordinate + 0.5
    for (let y = 16; y >= 9; y--) {
        levyCoords.push([startX, y]);
    }

    adjustedData[adjustedData.length - 1] = {
        ...lastPart,
        levy: true,
        levyCoords: levyCoords,
        levyColor: '#555555', // Color for the Levy flight line
    };

    return adjustedData;
},
  
  
  'Rosy Cheeks': (accessoryData, skinTone) => {
    const cheekColors = ['#e6aeae', '#d09c6e', '#aa7b54', '#763b1a']; // red, blue, green, yellow
    return accessoryData.map(trait => {
      return {
        ...trait,
        color: [cheekColors[skinTone]]
      };
    });
  },
  Mole: (accessoryData, skinTone) => {
    const moleColors = ['#9a8e8b', '#917656', '#765f43', '#52321a']; // brown, red, dark gray, goldenrod
    return accessoryData.map((trait, index) => {
      if (index === 0 && trait.part === 'mole') {
        return {
          ...trait,
          color: [moleColors[skinTone]]
        };
      }
      return trait;
    });
  },
  'Hot Lipstick': (faceData) => {
    return faceData.map(trait => {
      if (trait.part === 'mouth') {
        return {
          ...trait,
          color: ['#C42110']
        };
      }
      return trait;
    });
  },
  'Dark Lipstick': (faceData) => {
    return faceData.map(trait => {
      if (trait.part === 'mouth') {
        return {
          ...trait,
          color: ['#000000']
        };
      }
      return trait;
    });
  },
  'Purple Lipstick': (faceData) => {
    return faceData.map(trait => {
      if (trait.part === 'mouth') {
        return {
          ...trait,
          color: ['#800080']
        };
      }
      return trait;
    });
  }
};

export function applyAccessoryConditions(selectedAccessories, selectedHair, skinColors) {
  return selectedAccessories.map(accessory => {
    if (accessory.name === 'Earring' && selectedHair && selectedHair.length === 'long') {
      return {
        ...accessory,
        data: conditions.Earring(accessory.data, selectedHair)
      };
    }
    if (accessory.name === 'Noggles') {
      return {
        ...accessory,
        data: conditions.Noggles(accessory.data)
      };
    }
    if (accessory.name === 'Cigarette') {
      return {
        ...accessory,
        data: conditions.Cigarette(accessory.data)
      };
    }
    if (accessory.name === 'Rosy Cheeks') {
      return {
        ...accessory,
        data: conditions['Rosy Cheeks'](accessory.data, skinColors)
      };
    }
    if (accessory.name === 'Mole') {
      return {
        ...accessory,
        data: conditions.Mole(accessory.data, skinColors)
      };
    }
    return accessory;
  });
}

export function applyFaceConditions(selectedFace, selectedAccessories, skinColors) {
  let modifiedFace = { ...selectedFace, data: [...selectedFace.data] };

  selectedAccessories.forEach(accessory => {
    if (conditions[accessory.name]) {
      if (['Hot Lipstick', 'Dark Lipstick', 'Purple Lipstick'].includes(accessory.name)) {
        modifiedFace.data = conditions[accessory.name](modifiedFace.data);
      }
    }
  });

  return modifiedFace;
}
