import React, { useEffect, useRef } from 'react';
import { useP5Context } from '../contexts/P5Context';
import p5 from 'p5';
import { resetNoiseFlag, drawBackground, applyNoiseEffectToCanvas } from '../utils/Utils';

const Viewport = () => {
  const { drawMethod, p5ContainerRef, settings, traits, setTraits, brushSize, setBrushSize } = useP5Context();
  const p5InstanceRef = useRef(null);

  useEffect(() => {
    if (!p5InstanceRef.current && p5ContainerRef.current) {
      const sketch = (p) => {
        let canvasWidth, canvasHeight, cellSize, gridSide, customFont;

        const calculateDimensions = () => {
          canvasWidth = p.windowWidth;
          canvasHeight = p.windowHeight;
          gridSide = Math.min(canvasWidth, canvasHeight);
          cellSize = gridSide / 24; // 24x24 grid
        };

        p.preload = () => {
          customFont = p.loadFont('./fonts/webfonts/jgs7.woff');
        };

        p.setup = () => {
          calculateDimensions();
          p.createCanvas(canvasWidth, canvasHeight, p.WEBGL);

          p.colorMode(p.RGB, 255); // Use p.RGB instead of RGB

          p.setAttributes('antialias', true);
          p.pixelDensity(p.displayDensity());
          p.angleMode(p.DEGREES);
          p.textFont(customFont);

          let initialBrushSize = 0.420 * (p.width / 420);
          setBrushSize(initialBrushSize);

          if (settings.fps === 0) {
            p.noLoop();
            p.redraw(); // Ensure canvas is drawn at least once
          } else {
            p.frameRate(settings.fps);
            p.loop();
          }

          // Store the draw method in the instance
          p.customDrawMethod = drawMethod;
        };

        p.draw = () => {
          p.clear();
          drawBackground(p, cellSize, false);

          p.push();
          p.translate(-p.width / 2, -p.height / 2);
          p.translate((p.width - gridSide) / 2, (p.height - gridSide) / 2);

          if (p.customDrawMethod) {
            p.customDrawMethod(p, cellSize, traits, setTraits, brushSize);
          }

          p.pop();
          applyNoiseEffectToCanvas(p, settings.noise);
        };

        p.windowResized = () => {
          calculateDimensions();
          p.resizeCanvas(p.windowWidth, p.windowHeight);
          p.redraw();
        };
      };

      p5InstanceRef.current = new p5(sketch, p5ContainerRef.current);

      return () => {
        if (p5InstanceRef.current) {
          p5InstanceRef.current.remove();
          p5InstanceRef.current = null;
        }
      };
    }
  }, []);

  useEffect(() => {
    if (p5InstanceRef.current) {
      if (settings.fps === 0) {
        p5InstanceRef.current.noLoop();
      } else {
        p5InstanceRef.current.loop();
        p5InstanceRef.current.frameRate(settings.fps);
      }
    }
  }, [settings.fps]);

  useEffect(() => {
    if (p5InstanceRef.current) {
      p5InstanceRef.current.customDrawMethod = drawMethod;
      p5InstanceRef.current.redraw();
    }
  }, [drawMethod]);

  return <div ref={p5ContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default Viewport;
